<template>
    <div id="Works" v-if="content">
        <div class="mainbox">
            <div class="title">{{content.title}}
                <div class="tab" v-if="id">
                    <button @click="id=null" class="btn btn-3 mr-2"><i class="fas fa-backward mr-2"></i> Back</button>
                </div>
            </div>
            <div class="row mt-5 boxes">
                <template v-if="!id">
                    <div v-for="work in works" :key="work.id" class="col-md-6 mb-3"><img :src="work.thumbnail"
                            :alt="work.company" @click="showWork(work.id)" class="opacitity" /></div>
                </template>
                <template v-else>
                    <div>
                        <img :src="work.thumbnail" :alt="work.company" />
                    </div>
                    <div v-if="work.images && work.images.length > 0" class="divider"></div>
                    <div v-for="image in work.images" :key="image" class="mt-3 text-center">
                        <img :src="image" :alt="work.company" class="mx-auto d-block" />
                    </div>
                </template>
            </div>
        </div><!-- mainbox -->
    </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
export default {
    name: "Works",
    data() {
        return {
            content: {},
            works: {},
            id: false,
            work: {},
        }
    },
    computed: {
        ...mapGetters(['getAPI', 'getSelectedLang'])
    },
    methods: {
        showWork(id) {
            this.id = id
            this.work = this.works.find(work => work.id === id)
        }
    },
    created() {
        axios.get(this.getAPI + 'works.json')
            .then(res => {
                console.log(res)
                this.content = res.data[this.getSelectedLang]
                this.works = this.content?.works ? this.content.works.sort((a, b) => a.id - b.id) : []
            })
            .catch(err => console.log(err))
    }
}
</script>
<style scoped>
.boxes {
    text-align: center;
}

.divider {
    width: 90%;
    margin: 30px 5%;
    height: 1px;
    background-color: #ededed;
}

.boxes img {
    max-width: 100%;
    border-radius: 20px;
    transition: 0.8s;
}

.boxes img:hover {
    border-radius: 5px;
    transition: 2.3s;
}

.opacitity {
    opacity: 0.6;}
.opacitity:hover {
    opacity: 1;}
</style>
